import {useContext, useState, useRef, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { DataContext } from '../../AppContext/DataContext'
import {BiUserCircle, BiInfoCircle} from 'react-icons/bi'
import {MdOutlineManageAccounts, MdHome, MdLogout, MdOutlineDashboard, MdMenu, MdLogin} from 'react-icons/md'
import {FaSignOutAlt} from 'react-icons/fa'
import {FiUserPlus} from 'react-icons/fi'
import WebComponent from './WebComponent'
import MobileComponent from './MobileComponent'
// Assets==
import letras from '../../assets/Finales/letras_naranja.png'
import logo from '../../assets/Finales/logo.png'
import logoBlanco from '../../assets/Finales/logo-blanco.png'

export default function Header() {

  // Navegación
  const navigate = useNavigate()

  // Context
  const {user, signOut} = useContext(DataContext)

  // Hookk de Referencia para el menu Web
  const menuRef = useRef(null)
  const mobileMenuRef = useRef(null)

  // States
  const [modal, setModal] = useState(false)
  const [mobileModal, setMobileModal] = useState(false)

  useEffect(() => {

    let handler = (e)=>{
      if(!menuRef.current?.contains(e.target)){
        setModal(false)
      }
      if(!mobileMenuRef.current?.contains(e.target)){
        setMobileModal(false)
      }
    }

    document.addEventListener("mousedown", handler)

    return () => {
      document.removeEventListener("mousedown", handler)
    }
  })

  return (
    <>
      {/* Versión Web */}
      <WebComponent>
        <div className='headerBgClass fixed flex flex-row top-0 left-0 w-full h-[100px] py-2 px-[5%] bg-c1 shadow-md shadow-c2light items-center justify-between'>
          <div className='flex flex-row items-center justify-center'>
            <div 
              className='flex flex-row items-center justify-center cursor-pointer'
              onClick={()=>navigate('/')}
            >
              <img src={logo} alt="logo" width={55} className='mr-2' />
              <img src={letras} alt="logo" width={150} />
            </div>
            <div className='flex flex-row items-center justify-center ml-8 text-c2'>
              <p className='px-3  text-[22px] cursor-pointer hover:underline hover:text-c2light border-r-2 border-c2' onClick={()=> navigate('/')}>Inicio</p>
              <p className='px-3  text-[22px] cursor-pointer hover:underline hover:text-c2light border-r-2 border-c2' onClick={()=> navigate('/tours')}>Tours</p>
              <p className='px-3  text-[22px] cursor-pointer hover:underline hover:text-c2light' onClick={()=>navigate('/info')}>Información</p>
            </div>
          </div>

          {
            user ?
            <div className='cursor-pointer' ref={menuRef}>
              <div 
                className='flex flex-row items-center justify-center bg-gradient-to-r from-c2 to-c3 text-white py-2 px-3 rounded-tl-[14px] rounded-br-[14px] rounded-tr-[7px] rounded-bl-[7px] hover:bg-gradient-to-r hover:from-c2light hover:to-c3light'
                onClick={()=>setModal(!modal)}
              >   

                <p 
                  className='text-[20px] mr-2'
                >
                  {user.attributes.name + " " + user.attributes.family_name}
                </p>
                {
                  user.attributes.picture ?
                  <div>
                    <img src={user.attributes.picture} alt="profilePicture" width={35} className='rounded-full' />  
                  </div>
                  :
                  <BiUserCircle className='w-[35px] h-[35px] transition-all duration-100 fill-white'/>
                }
              </div>  
              {modal &&
                <div className='fixed top-[80px] right-[20px] bg-gradient-to-br from-c2 to-c3 text-white text-[18px] rounded-md transition-transform'>
                  <div className='flex flex-col justify-start'>
                    <div 
                      className='flex flex-row items-center justify-start py-2 px-4 cursor-pointer hover:bg-c2light rounded-md'
                      onClick={()=>{
                          setModal(false)
                          navigate('/cuenta')
                      }}
                    >
                      <MdOutlineManageAccounts className='w-[18px] h-[18px]'/>
                      <p className='ml-2'>Cuenta</p>
                    </div>

                    {/* Aquí los links dependiendo de los permisos */}
                    {/* Panel de SuperUsuario */}
                    {(user?.attributes["custom:roles"] === 'super' || user?.attributes["custom:roles"] === 'absolute') &&
                      <div className='flex flex-col'>
                        <div 
                          className='flex flex-row items-center justify-start py-2 px-4 cursor-pointer hover:bg-c2light rounded-md'
                          onClick={()=>{
                              setModal(false)
                              navigate('/panel_super')
                          }}
                        >
                          <MdOutlineDashboard className='w-[18px] h-[18px]'/>
                          <p className='ml-2'>Panel Super Usuario</p>
                        </div>
                      </div>  
                    }
                    {/* Panel de Administrador */}
                    {user?.attributes["custom:roles"] === 'admin' &&
                      <div className='flex flex-col'>
                        <div 
                          className='flex flex-row items-center justify-start py-2 px-4 cursor-pointer hover:bg-c2light rounded-md'
                          onClick={()=>{
                              setModal(false)
                              navigate('/panel_admin')
                          }}
                        >
                          <MdOutlineDashboard className='w-[18px] h-[18px]'/>
                          <p className='ml-2'>Panel Administrador</p>
                        </div>
                      </div>  
                    }
                    {/* Panel de Agente */}
                    {user?.attributes["custom:roles"] === 'agent' &&
                      <div className='flex flex-col'>
                        <div 
                          className='flex flex-row items-center justify-start py-2 px-4 cursor-pointer hover:bg-c2light rounded-md'
                          onClick={()=>{
                              setModal(false)
                              navigate('/panel_agente')
                          }}
                        >
                          <MdOutlineDashboard className='w-[18px] h-[18px]'/>
                          <p className='ml-2'>Panel Agente Tourify</p>
                        </div>
                      </div>  
                    }
                    {/* Panel de Usuario */}
                    {user?.attributes["custom:roles"] === 'user' &&
                      <div className='flex flex-col'>
                        <div 
                          className='flex flex-row items-center justify-start py-2 px-4 cursor-pointer hover:bg-c2light rounded-md'
                          onClick={()=>{
                              setModal(false)
                              navigate('/panel_usuario')
                          }}
                        >
                          <MdOutlineDashboard className='w-[18px] h-[18px]'/>
                          <p className='ml-2'>Panel Usuario</p>
                        </div>
                      </div>  
                    }
                    <div 
                      className='flex flex-row items-center justify-start py-2 px-4 cursor-pointer hover:bg-c2light rounded-md'
                      onClick={()=>{
                        setModal(false)
                        signOut()
                      }}
                    >
                      <FaSignOutAlt className='w-[18px] h-[18px]'/>
                      <p className='ml-2'>Cerrar sesión</p>
                    </div>
                  </div>
                </div>
              } 
            </div>
                
            :
            
            <div>
              <button 
                onClick={()=>navigate('/iniciar_sesion')} 
                className='bg-gradient-to-r from-c2 to-c3 font-semibold text-white text-[20px] py-2 px-3 rounded-tl-[14px] rounded-br-[14px] rounded-tr-[7px] rounded-bl-[7px] hover:scale-105 hover:bg-gradient-to-r hover:from-c2light hover:to-c3light'
              >
                Iniciar sesión
              </button>
              <button 
                onClick={()=>navigate('/registrar_usuario')} 
                className='ml-3'
              >
                <h1 
                  className='text-transparent bg-clip-text bg-gradient-to-r from-c2 to-c3 font-semibold text-[20px] hover:underline hover:bg-gradient-to-r hover:from-c2light hover:to-c3light'>
                  Registrarse
                </h1>
              </button>
            </div>
          }      
        </div>
      </WebComponent>

      
      {/* Versión Móvil */}
      <MobileComponent>
        <div className='headerBgClass fixed flex flex-row top-0 left-0 w-full h-[70px] py-2 px-[2%] bg-c1 shadow-md shadow-c2light items-center justify-between'>
          {/* Logo */}
          <div className='flex flex-row items-center justify-center'>
            <div 
              className='flex flex-row items-center justify-center cursor-pointer'
              onClick={()=>navigate('/')}
            >
              <img src={logo} alt="logo" width={40} className='mr-1' />
              <img src={letras} alt="logo" width={120} />
            </div>
          </div>

          {/* Menu, modal */}
          <div className='cursor-pointer font-mon text-c2' ref={mobileMenuRef}>
            <div 
              className='flex flex-row items-center justify-center'
              onClick={()=>setMobileModal(!mobileModal)}
            >   

              <p className='font-bold text-[20px] mr-2 transition-all duration-100'>Menú</p>
              <MdMenu className='w-[40px] h-[40px] transition-all duration-100'/>
            </div>  

            {/* Modal */}
            {mobileModal &&
              <div className='fixed top-[70px] right-[10px] bg-gradient-to-br from-c2 to-c3 text-white text-[18px] rounded-md transition-transform duration-300'>
                <div className='flex flex-col justify-start font-semibold'>
                  {
                    user &&
                    <div className='flex flex-col items-center justify-center text-justify mt-2 mb-1'>
                      <p className='text-[15px] font-normal'>Bienvenid@</p>
                      <p className='text-[18px] font-semibold mx-3'>{user.attributes.name + ' ' + user.attributes.family_name}</p>
                    </div>
                  }
                  <div 
                    className='flex flex-row items-center justify-start py-2 px-4 cursor-pointer hover:bg-slate-200 rounded-md'
                    onClick={()=>{
                        setMobileModal(false)
                        navigate('/')
                    }}
                  >
                    <MdHome className='w-[18px] h-[18px]'/>
                    <p className='ml-2'>Inicio</p>
                  </div>
                  <div 
                    className='flex flex-row items-center justify-start py-2 px-4 cursor-pointer hover:bg-slate-200 rounded-md'
                    onClick={()=>{
                        setMobileModal(false)
                        navigate('/tours')
                    }}
                  >
                    <img src={logoBlanco} alt="logoblanco" width={18} />
                    <p className='ml-2'>Tours</p>
                  </div>
                  <div 
                    className='flex flex-row items-center justify-start py-2 px-4 cursor-pointer hover:bg-slate-200 rounded-md'
                    onClick={()=>{
                        setMobileModal(false)
                        navigate('/info')
                    }}
                  >
                    <BiInfoCircle className='w-[18px] h-[18px]'/>
                    <p className='ml-2'>Información</p>
                  </div>

                  {/* Dependen de autenticación */}

                  {
                    user ?
                    // Cuenta, cerrar sesión
                    <div>
                      {/* Panel dependiendo de nivel de autenticación */}
                      {/* Aquí los links dependiendo de los permisos */}
                      {/* Panel de SuperUsuario */}
                      {(user?.attributes["custom:roles"] === 'super' || user?.attributes["custom:roles"] === 'absolute') &&
                        <div className='flex flex-col'>
                          <div 
                            className='flex flex-row items-center justify-start py-2 px-4 cursor-pointer hover:bg-slate-200 rounded-md'
                            onClick={()=>{
                                setMobileModal(false)
                                navigate('/panel_super')
                            }}
                          >
                            <MdOutlineDashboard className='w-[18px] h-[18px]'/>
                            <p className='ml-2'>Panel Super Usuario</p>
                          </div>
                        </div>  
                      }
                      {/* Panel de Administrador */}
                      {user?.attributes["custom:roles"] === 'admin' &&
                        <div className='flex flex-col'>
                          <div 
                            className='flex flex-row items-center justify-start py-2 px-4 cursor-pointer hover:bg-slate-200 rounded-md'
                            onClick={()=>{
                                setMobileModal(false)
                                navigate('/panel_admin')
                            }}
                          >
                            <MdOutlineDashboard className='w-[18px] h-[18px]'/>
                            <p className='ml-2'>Panel Administrador</p>
                          </div>
                        </div>  
                      }
                      {/* Panel de Agente */}
                      {user?.attributes["custom:roles"] === 'agent' &&
                        <div className='flex flex-col'>
                          <div 
                            className='flex flex-row items-center justify-start py-2 px-4 cursor-pointer hover:bg-slate-200 rounded-md'
                            onClick={()=>{
                                setMobileModal(false)
                                navigate('/panel_agente')
                            }}
                          >
                            <MdOutlineDashboard className='w-[18px] h-[18px]'/>
                            <p className='ml-2'>Panel Agente Tourify</p>
                          </div>
                        </div>  
                      }
                      {/* Panel de Usuario */}
                      {user?.attributes["custom:roles"] === 'user' &&
                        <div className='flex flex-col'>
                          <div 
                            className='flex flex-row items-center justify-start py-2 px-4 cursor-pointer hover:bg-slate-200 rounded-md'
                            onClick={()=>{
                                setMobileModal(false)
                                navigate('/panel_usuario')
                            }}
                          >
                            <MdOutlineDashboard className='w-[18px] h-[18px]'/>
                            <p className='ml-2'>Panel Usuario</p>
                          </div>
                        </div>  
                      }
                      {/* Cuenta */}
                      <div 
                        className='flex flex-row items-center justify-start py-2 px-4 cursor-pointer hover:bg-slate-200 rounded-md'
                        onClick={()=>{
                            setMobileModal(false)
                            navigate('/cuenta')
                        }}
                      >
                        <MdOutlineManageAccounts className='w-[18px] h-[18px]'/>
                        <p className='ml-2'>Cuenta</p>
                      </div>
                      {/* Cerrar sesión */}
                      <div 
                        className='flex flex-row items-center justify-start py-2 px-4 cursor-pointer hover:bg-slate-200 rounded-md'
                        onClick={()=>{
                            setMobileModal(false)
                            signOut()
                        }}
                      >
                        <MdLogout className='w-[18px] h-[18px]'/>
                        <p className='ml-2'>Cerrar sesión</p>
                      </div>
                    </div>
                    :
                    // Iniciar sesión, registro
                    <div>
                      {/* Iniciar sesión */}
                      <div 
                        className='flex flex-row items-center justify-start py-2 px-4 cursor-pointer hover:bg-slate-200 rounded-md'
                        onClick={()=>{
                            setMobileModal(false)
                            navigate('/iniciar_sesion')
                        }}
                      >
                        <MdLogin className='w-[18px] h-[18px]'/>
                        <p className='ml-2'>Iniciar sesión</p>
                      </div>
                      {/* Registrarse */}
                      <div 
                        className='flex flex-row items-center justify-start py-2 px-4 cursor-pointer hover:bg-slate-200 rounded-md'
                        onClick={()=>{
                            setMobileModal(false)
                            navigate('/registrar_usuario')
                        }}
                      >
                        <FiUserPlus className='w-[18px] h-[18px]'/>
                        <p className='ml-2'>Registrarse</p>
                      </div>

                    </div>
                  }
                </div>
              </div>
            } 
          </div>
                
        </div>
      </MobileComponent>
    </>
  )
}
