import hsImage from '../../assets/letraT.png'
import infoImage from '../../assets/info.png'

// Custom Hotspot attributes
export const customHotspotTooltip = (hotSpotDiv) => {
    hotSpotDiv.classList.add('custom-tooltip');
    hotSpotDiv.innerHTML = `
        <div class="p-2 rounded-full bg-c1 bg-opacity-70 hover:bg-opacity-100 hover:scale-125">
            <img src=${hsImage} class="w-[30px] h-[30px]"/>
        </div>
    `;
};

// Custom Info Hotspot attributes
export const customInfoTooltip = (hotSpotDiv, args) => {
    hotSpotDiv.classList.add('custom-tooltip', 'relative');
    hotSpotDiv.innerHTML = `
        <div class="p-1 rounded-full hover:scale-110 rounded-tl-[10px] rounded-tr-[5px] rounded-bl-[5px] rounded-br-[10px] bg-gradient-to-br from-c2 to-c3">
            <img src=${infoImage} class="w-[30px] h-[30px]"/>
        </div>
        <div class="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-full opacity-0 transition-opacity duration-200">
            <div class="bg-black opacity-[.85] min-w-[300px] text-white mb-3 py-3 px-3 relative rounded-2xl">
                <p class="text-justify">${args.text}</p>
                <div class="absolute w-[20px] h-[20px] bg-black left-1/2 bottom-[-10px] transform translate-x-[-50%] rotate-45"></div>
            </div>
        </div>
        
    `;

    // Add a click event handler to the hotspot's div
    hotSpotDiv.onclick = function(event) {
        // Prevent the event from bubbling up to the tooltip div
        event.stopPropagation();

        // Find the tooltip div
        const tooltipDiv = hotSpotDiv.querySelector('.absolute');

        // Toggle the opacity of the tooltip div
        if (tooltipDiv.style.opacity === '1') {
            tooltipDiv.style.opacity = '0';
        } else {
            tooltipDiv.style.opacity = '1';
        }
    };

    document.addEventListener('click', function() {
        // Find the tooltip div
        const tooltipDiv = hotSpotDiv.querySelector('.absolute');

        // Hide the tooltip div
        tooltipDiv.style.opacity = '0';
    });
};
