import React, {createContext, useState, useEffect} from 'react'
// Navegación
import { useNavigate, useLocation } from 'react-router-dom'
// AWS
import { Auth, Hub } from 'aws-amplify'
// Swal
import Swal from 'sweetalert2'


/*
  CÓDIGOS DE ÉXITO Y FALLO
  1: EXITOSO
  2: RESPUESTA NO DESEADA
  3: ERROR
*/

// Se crea el context API
export const DataContext = createContext()

export default function DataProvider({children}) {

  // Navegación
  const navigate = useNavigate()
  // Location
  const location = useLocation()
  if(location.state){
      console.log(location.state.prevUrl)
  }
    

  // Variables
  // Para saber dentro de toda la página si está cargando algún dato
  const [loading, setLoading] = useState(false)
  // Para saber si existe alguna sesión iniciada
  const [user, setUser] = useState()


  // Funciones

  // Función que chequea si el usuario está loggeado o no
  useEffect(() => {
    async function checkUser() {
      try {
        const userInfo = await Auth.currentAuthenticatedUser();
        setUser(userInfo);
      } catch (error) {
        console.log('error checking user', error);
        // Handle the error appropriately
      }
    }
    return () => {
      checkUser();
    }
  }, []);

  // Para añadir el listener global de auto sign in
  useEffect(() => {

    const hubListener = Hub.listen('auth', ({ payload }) => {
      const { event } = payload;
      if (event === 'autoSignIn') {
        Auth.currentAuthenticatedUser().then(user => {
          console.log("Usuario desde Hub listener: ", user)
          setUser(user)
          //handleProfilePictureUpload(user);
        }).catch(err => {
          console.log("Error al obtener usuario autenticado!", err);
        });
      } else if (event === 'autoSignIn_failure') {
        console.log("Error al iniciar sesión automáticamente, redirigiendo a iniciar sesión");
        navigate('/iniciar_sesion');
      }
    });
  
    // Cleanup: Stop listening when the component is unmounted
    return () => {
      hubListener(); // This will remove the listener
    };
    // eslint-disable-next-line
  }, []);
  
  

  // Función para crear una cuenta, recibe el objeto data como parámetro el cual contiene la información para registrarse
  const signUp = async(data) => {

    setLoading(true)
    //const fileLocation = `${tour.userId}/Tours/${tour.id}/${fileName}`
    try{

      const {user} = await Auth.signUp({
        username:data.email,
        password:data.password, 
        attributes:{
          email:data.email,
          name:data.nombre,
          family_name:data.apellido,
          phone_number:data.numero,
          zoneinfo:data.ciudad,
          'custom:roles': data.roles,
          'custom:company':data.empresa,
        },
        autoSignIn:{
          enabled:true,
        }
      })
      console.log(user)

      navigate(`/confirmar_email/${data.email}`, {
        state:{
          prevUrl:'',
        }
      })
        
    }catch(error){
      console.log('ERROR AL REGISTRARSE!:', error)
      Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      }).fire({
        icon:'error',
        title:'Error al crear usuario!',
        text:error
      })
      
    }
    // Deja de cargar,
    setLoading(false)
    
  }

  // Función para confirmar el mail con el código proporcionado
  const handleConfirm = async(data) => {
    try{
      const response = await Auth.confirmSignUp(data.email, data.code)
      console.log(response)
      Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      }).fire({
        icon:'success',
        title:'Exitoso!',
        text:"Usuario creado correctamente!"
      })
      console.log("Navegando a confirmar email")
      navigate('/')
    }catch(error){
      console.log(error)
      Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      }).fire({
        icon:'error',
        title:'Error al verificar email!',
        text:error
      })
    }
  }

  // Función para reenviar el código
  const resendCode = async(email) => {
    setLoading(true)
    try{
      await Auth.resendSignUp(email)
      console.log('Código enviado correctamente')
      Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      }).fire({
        icon:'success',
        title:'Exitoso!',
        text:"Código enviado correctamente!"
      })

      setLoading(false)
      return 1
    }catch(error){
      console.log(error)
      Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      }).fire({
        icon:'error',
        title:'Error al enviar código!',
        text:error
      })
      setLoading(false)
      return 2
    }
  }

  // Función para iniciar sesión
  const signIn = async(data) => {
    setLoading(true)
    try{
      const response = await Auth.signIn(data.email, data.password)
      console.log(response)
      let userInfo = await Auth.currentAuthenticatedUser()
      setUser(userInfo)
      Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      }).fire({
        icon:'success',
        title:'Exitoso!',
        text:"Inició sesión correctamente!"
      })
      if(location.state){
        console.log("NAVEGANDO A OTRA RUTA")
        navigate(`${location.state.prevUrl}`,)
      }else{
        console.log('NO HAY STATE')
        navigate('/')
      }

    }catch(error){
      console.log(error)
      Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      }).fire({
        icon:'error',
        title:'Error al iniciar sesión!',
        text:error
      })
    }
    setLoading(false)
  }

  // Función para cerrar sesión en todos los dispositivos 
  const signOut = async() => {
    setLoading(true)
    try{
      await Auth.signOut({global:true})
      Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      }).fire({
        icon:'success',
        title:'Exitoso!',
        text:"Cerró sesión correctamente!"
      })
      console.log('Cerro sesión')
    }catch(error){
      console.log(error)
      Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      }).fire({
        icon:'success',
        title:'Error al cerrar sesión!',
        text:error
      })
    }
    setUser(null)
    navigate('/',{
      state:{
          prevUrl:'',
      }
    })
    setLoading(false)
  }

  return (
    <DataContext.Provider value={{
      user,
      setUser,
      loading,
      setLoading,
      signUp,
      handleConfirm,
      resendCode,
      signIn,
      signOut,
    }}>
      {children}
    </DataContext.Provider>
  )
}
