// UseEffect para AOS
import { useEffect, lazy, Suspense } from "react";
// AOS
import AOS from 'aos'
import 'aos/dist/aos.css'

// DataProvider de Context
import DataProvider from "./AppContext/DataContext";

// Router
import { Route, Routes } from 'react-router-dom';

// Authenticator
import Authenticator from "./Components/reusable/Authenticator"

// Main imports (not lazy)
import Main from "./Components/views/Main/Main";
import Content from "./Components/views/Main/Content";
import NoPermission from "./Components/views/Auth/NoPermission";
import Login from "./Components/views/Auth/Login";
import Signup from "./Components/views/Auth/Signup";
import Loader from "./Components/reusable/Loader";

// Lazy imports (only loadable when requested)
const NotFound = lazy(()=> import("./Components/reusable/NotFound"))
const SignUpSuper = lazy(()=> import( "./Components/views/Auth/SignUpSuper"))
const ConfirmEmail = lazy(()=> import( "./Components/views/Auth/ConfirmEmail"))
const ResetPassword = lazy(()=> import( "./Components/views/Auth/ResetPassword"))
const ConfirmResetPassword = lazy(()=> import( "./Components/views/Auth/ConfirmResetPassword"))
const CrearTour = lazy(()=> import("./Components/views/Tour/CrearTour"))
const SuperPanel = lazy(()=> import("./Components/views/Super/SuperPanel"))
const VerUsuariosSuper = lazy(()=> import("./Components/views/Super/VerUsuariosSuper"))
const RequestTour = lazy(()=> import("./Components/views/Agent/RequestTour"))
const PickPlan = lazy(()=> import("./Components/views/Agent/PickPlan"))
const AdminPanel = lazy(()=> import("./Components/views/Admin/AdminPanel"))
const AgentPanel = lazy(()=> import("./Components/views/Agent/AgentPanel"))
const UserPanel = lazy(()=> import("./Components/views/User/UserPanel"))
const ScheduleMeet = lazy(()=> import("./Components/views/Agent/ScheduleMeet"))
const ConfirmMeeting = lazy(()=> import("./Components/views/Agent/ConfirmMeeting"))
const SolicitudesAgente = lazy(()=> import("./Components/views/Agent/SolicitudesAgente"))
const GestionarPlanes = lazy(()=> import("./Components/views/Super/GestionarPlanes"))
const SolicitudesSuper = lazy(()=> import("./Components/views/Super/SolicitudesSuper"))
const CrearTourVirtual = lazy(()=> import("./Components/views/Tour/CrearTourVirtual"))
const SubirImagenes = lazy(()=> import("./Components/views/Tour/SubirImagenes"))
const EditarTour = lazy(()=> import("./Components/views/Tour/EditarTour"))
const Tour = lazy(()=> import("./Components/views/Tour/Tour"))
const GestionarDescuentos = lazy(()=> import('./Components/views/Super/GestionarDescuentos'))
//const TourTest = lazy(()=> import("./Components/views/Tour/TourTest"))
const AbsolutePanel = lazy(()=> import("./Components/views/Absolute/AbsolutePanel"))
const GestionarProductores = lazy(()=>import("./Components/views/Super/GestionarProductores"))

function App() {

  useEffect(() => {
    AOS.init({
      duration:1000,
      easing:'ease-in-out-back'
    })
  }, []);
  
  return (
    <DataProvider>
      <Suspense fallback={<Loader/>}>
        <Routes>
          <Route element={<Main/>}>
            {/* Rutas que no necesitan autenticación y rutas de usuario */}
            <Route path='/' element={<Content/>}/>
            <Route path='/permiso_denegado' element={<NoPermission/>}/>
            <Route path="/iniciar_sesion" element={<Login/>}/>
            <Route path="/resetear_clave" element={<ResetPassword/>}/>
            <Route path='/confirmar_resetear_clave/:userInfo' element={<ConfirmResetPassword/>}/>
            <Route path="/registrar_usuario" element={<Signup/>}/>
            <Route path='/confirmar_email/:userInfo' element={<ConfirmEmail/>}/>
            <Route path='/panel_usuario' element={<UserPanel/>}/>
            <Route path='/tour/:tour_id' element={<Tour/>}/>
            {/* Prueba */}
            <Route path='/info' element={<PickPlan/>}/>

            {/* Rutas de agente */}
            <Route path='/elegir_plan' element={<Authenticator role="agent"><PickPlan/></Authenticator>}/>
            <Route path='/solicitar_tour/:plan_id/:plan_qtty' element={<Authenticator role="agent"><RequestTour/></Authenticator>}/>
            <Route path='/panel_agente' element={<Authenticator role="agent"><AgentPanel/></Authenticator>}/>
            <Route path='/agendar_reunion/:meeting_id' element={<Authenticator role="agent"><ScheduleMeet/></Authenticator>}/>
            <Route path='/confirmar_reunion' element={<Authenticator role="agent"><ConfirmMeeting/></Authenticator>}/>
            <Route path='/solicitudes' element={<Authenticator role="agent"><SolicitudesAgente/></Authenticator>}/>

            {/* Rutas de administrador */}
            <Route path='/panel_admin' element={<Authenticator role="admin"><AdminPanel/></Authenticator>}/>
            <Route path="/solicitudes_admin" element={<Authenticator role="admin"><SolicitudesSuper/></Authenticator>}/>
            <Route path="/crear_tour" element={<Authenticator role="admin"><CrearTourVirtual/></Authenticator>}/>
            <Route path="/subir_imagenes/:tour_id" element={<Authenticator role="admin"><SubirImagenes/></Authenticator>}/>
            <Route path="/editar_tour/:tour_id" element={<Authenticator role="admin"><EditarTour/></Authenticator>}/>
            <Route path="/crear_tour/:tour_id" element={<Authenticator role="admin"><CrearTour/></Authenticator>}/>

            {/* Rutas de Superusuario */}
            <Route path="/panel_super" element={<Authenticator role="super"><SuperPanel/></Authenticator>}/>
            <Route path="/registrar_super" element={<Authenticator role="super"><SignUpSuper/></Authenticator>}/>
            <Route path="/crm_super" element={<Authenticator role="super"><VerUsuariosSuper/></Authenticator>}/>
            <Route path="/gestionar_planes" element={<Authenticator role="super"><GestionarPlanes/></Authenticator>}/>
            <Route path="/gestionar_descuentos" element={<Authenticator role="super"><GestionarDescuentos/></Authenticator>}/>
            <Route path="/gestionar_productores" element={<Authenticator role="super"><GestionarProductores/></Authenticator>}/>

            {/* Rutas de Usuario Absoluto */}
            <Route path="/panel_absoluto" element={<Authenticator role="absolute"><AbsolutePanel/></Authenticator>}/>

            {/* Página no encontrada */}
            <Route path="*" element={<NotFound/>}/>

          </Route>
        </Routes>
      </Suspense>
    </DataProvider>
  );
}

export default App;
