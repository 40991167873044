import React, {useContext} from 'react'
import { DataContext } from '../../../AppContext/DataContext'
import Swal from 'sweetalert2'
import Select from 'react-select'
import {FaSignInAlt} from 'react-icons/fa'
import WebComponent from '../../reusable/WebComponent'
import MobileComponent from '../../reusable/MobileComponent'

export default function SignUpUser({userInfo, setUserInfo}) {

  // Cities in Bolivia
  const cities = [
    { value: 'Santa Cruz', label: 'Santa Cruz' },
    { value: 'La Paz', label: 'La Paz' },
    { value: 'Cochabamba', label: 'Cochabamba' },
    { value: 'Tarija', label: 'Tarija' },
    { value: 'Sucre', label: 'Sucre' },
    { value: 'Beni', label: 'Beni' },
    { value: 'Oruro', label: 'Oruro' },
    { value: 'Potosí', label: 'Potosí' },
    { value: 'Pando', label: 'Pando' },
  ]

  // States
  const {signUp} = useContext(DataContext)

  const handleCityChange = (selectedOption) => {
    //setCity(selectedOption.value)
    setUserInfo({...userInfo, ciudad:selectedOption.value})
  }

  // Función para manejar el signUp
  const handleSignUp = (e) => {
    e.preventDefault()
    console.log(userInfo)

    // Check if email name and last name are correct
    if(userInfo.email && userInfo.nombre && userInfo.apellido){
      // Check if city exists
      if(userInfo.ciudad){
        // Check if passwords are equal
        if(userInfo.password && userInfo.password === userInfo.confirmedPassword){
          signUp(userInfo)
        }else{
          Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          }).fire({
            icon:'error',
            title:'Error!',
            text:"Las contraseñas no coinciden!"
          })
        }
        
      }else{
        Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        }).fire({
          icon:'error',
          title:'Error!',
          text:"Error al elegir ciudad!"
        })
      }
    }else{
      Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      }).fire({
        icon:'error',
        title:'Error!',
        text:"Error en los campos (Email, Nombre o Apellido)!"
      })
    }
  }
  // Styles for react select
  const styles = {
    control: (provided, state) => ({
        ...provided,
        color: 'slategray', 
        borderColor: state.isFocused ? 'transparent' : state.isHovered ? 'transparent' : provided.borderColor,
        boxShadow: state.isFocused ? 'none' : provided.boxShadow,
        minWidth: '200px',
    }),
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'white' : state.isFocused ? 'white' : 'slategray',
        backgroundColor: state.isSelected ? '#e14421' : state.isFocused ? '#e8684c' : null,
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'slategray',
    }),
  }

   
  return (
    <>
      {/* Versión Web */}
      <WebComponent>
        <div className='flex flex-col mb-5 bg-white text-c2 font-mon px-[60px] min-w-[450px] min-h-[500px] rounded-tl-[36px] rounded-br-[36px] rounded-tr-[18px] rounded-bl-[18px] shadow-md shadow-c3 border-2 border-c2'>
          <form onSubmit={handleSignUp} autoComplete='off' className='flex flex-col w-full items-center justify-center h-full mt-[30px]'>
            <div className='flex flex-col w-full items-start'>
              <label className='text-[18px]'>Email</label>
              <input
                className='w-full font-sans py-1 px-3 rounded-md outline-none border-[1px] border-c2 font-thin' 
                type="email" 
                placeholder='nombre@ejemplo.com' 
                autoComplete='new-password'
                onChange={(e)=> setUserInfo({...userInfo, email:e.target.value})}
              />
              <label className='mt-4 text-[18px]'>Nombre(s)</label>
              <input
                className='w-full font-sans py-1 px-3 rounded-md outline-none border-[1px] border-c2 font-thin' 
                type="text" 
                placeholder='Ingresar nombre o nombres' 
                autoComplete='new-password'
                onChange={(e)=> setUserInfo({...userInfo, nombre:e.target.value})}
              />
              <label className='mt-4 text-[18px]'>Apellido(s)</label>
              <input
                className='w-full font-sans py-1 px-3 rounded-md outline-none border-[1px] border-c2 font-thin' 
                type="text" 
                placeholder='Ingresar apellido o apellidos' 
                autoComplete='new-password'
                onChange={(e)=> setUserInfo({...userInfo, apellido:e.target.value})}
              />
              <label className="mt-4 text-[18px]">Ciudad</label>
              <Select 
                options={cities} 
                className='w-full font-sans py-1 px-3 rounded-md outline-none border-[1px] border-c2 font-thin text-c1'
                defaultValue={cities.find(city => city.value === "Santa Cruz")}
                styles={styles} 
                placeholder="Seleccionar ciudad"
                onChange={handleCityChange}
              />
              <label className='mt-4 text-[18px]'>Contraseña</label>
              <input
                className='w-full font-sans py-1 px-3 rounded-md outline-none border-[1px] border-c2 font-thin' 
                type="password" 
                placeholder='Mínimo 8 caracteres' 
                autoComplete='new-password'
                onChange={(e)=> setUserInfo({...userInfo, password:e.target.value})}
              />
              <label className='mt-4 text-[18px]'>Repetir Contraseña</label>
              <input
                className='w-full font-sans py-1 px-3 rounded-md outline-none border-[1px] border-c2 font-thin' 
                type="password" 
                placeholder='Mínimo 8 caracteres' 
                autoComplete='new-password'
                onChange={(e)=> setUserInfo({...userInfo, confirmedPassword:e.target.value})}
              />
              
            </div>
            <div className='my-8'>
              <button 
                className='flex flex-row items-center justify-center bg-gradient-to-r from-c2 to-c3 text-white text-[20px] py-2 px-3 rounded-tl-[14px] rounded-br-[14px] rounded-tr-[7px] rounded-bl-[7px] hover:scale-105 hover:bg-gradient-to-r hover:from-c2light hover:to-c3light'
              >
                <p className='font-bold mr-2'>Confirmar</p>
                <FaSignInAlt/>
              </button>
            </div>
          </form>  
        </div>
      </WebComponent>    
      {/* Versión Móvil */}
      <MobileComponent>
        <div className='flex flex-col w-full bg-white text-c2 font-mon px-[60px] mb-5 rounded-tl-[36px] rounded-br-[36px] rounded-tr-[18px] rounded-bl-[18px] shadow-md shadow-c3 border-2 border-c2'>
          <form onSubmit={handleSignUp} autoComplete='off' className='flex flex-col w-full items-center justify-center h-full mt-[30px]'>
            <div className='flex flex-col w-full items-start'>
              <label className='text-[18px]'>Email</label>
              <input
                className='w-full font-sans py-1 px-3 rounded-md outline-none border-[1px] border-c2 font-thin' 
                type="email" 
                placeholder='nombre@ejemplo.com' 
                autoComplete='new-password'
                onChange={(e)=> setUserInfo({...userInfo, email:e.target.value})}
              />
              <label className='mt-4 text-[18px]'>Nombre(s)</label>
              <input
                className='w-full font-sans py-1 px-3 rounded-md outline-none border-[1px] border-c2 font-thin' 
                type="text" 
                placeholder='Ingresar nombre o nombres' 
                autoComplete='new-password'
                onChange={(e)=> setUserInfo({...userInfo, nombre:e.target.value})}
              />
              <label className='mt-4 text-[18px]'>Apellido(s)</label>
              <input
                className='w-full font-sans py-1 px-3 rounded-md outline-none border-[1px] border-c2 font-thin' 
                type="text" 
                placeholder='Ingresar apellido o apellidos' 
                autoComplete='new-password'
                onChange={(e)=> setUserInfo({...userInfo, apellido:e.target.value})}
              />
              <label className="mt-4 text-[18px]">Ciudad</label>
              <Select 
                options={cities} 
                className='w-full font-sans py-1 px-3 rounded-md outline-none border-[1px] border-c2 font-thin text-c1'
                defaultValue={cities.find(city => city.value === "Santa Cruz")}
                styles={styles} 
                placeholder="Seleccionar ciudad"
                onChange={handleCityChange}
              />
              <label className='mt-4 text-[18px]'>Contraseña</label>
              <input
                className='w-full font-sans py-1 px-3 rounded-md outline-none border-[1px] border-c2 font-thin' 
                type="password" 
                placeholder='Mínimo 8 caracteres' 
                autoComplete='new-password'
                onChange={(e)=> setUserInfo({...userInfo, password:e.target.value})}
              />
              <label className='mt-4 text-[18px]'>Repetir Contraseña</label>
              <input
                className='w-full font-sans py-1 px-3 rounded-md outline-none border-[1px] border-c2 font-thin' 
                type="password" 
                placeholder='Mínimo 8 caracteres' 
                autoComplete='new-password'
                onChange={(e)=> setUserInfo({...userInfo, confirmedPassword:e.target.value})}
              />
              
            </div>
            <div className='my-8'>
              <button 
                className='flex flex-row items-center justify-center bg-gradient-to-r from-c2 to-c3 text-white text-[20px] py-2 px-3 rounded-tl-[14px] rounded-br-[14px] rounded-tr-[7px] rounded-bl-[7px] hover:scale-105 hover:bg-gradient-to-r hover:from-c2light hover:to-c3light'
              >
                <p className='font-bold mr-2'>Confirmar</p>
                <FaSignInAlt/>
              </button>
            </div>
          </form>  
        </div>
      </MobileComponent>
    </>
  )
}
