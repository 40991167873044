import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Loader from './Loader';
import { DataContext } from '../../AppContext/DataContext';

const checkUserPermissions = (userInfo, role) => {
  const userRoles = userInfo?.attributes['custom:roles'];

  switch (role) {
    case 'absolute':
      if (userRoles === 'absolute') {
        console.log('Tiene permiso absoluto');
        return true;
      }
      break;
    case 'super':
      if (
        userRoles === 'absolute' || 
        userRoles === 'super'
      ){
        console.log('Tiene permiso de super');
        return true;
      }
      break;
    case 'admin':
      if (
        userRoles === 'absolute' || 
        userRoles === 'super' || 
        userRoles === 'admin'
      ) {
        console.log('Tiene permiso de admin');
        return true;
      }
      break;
    case 'agent':
      if (
        userRoles === 'absolute' ||
        userRoles === 'super' ||
        userRoles === 'admin' ||
        userRoles === 'agent'
      ) {
        console.log('Tiene permiso de agente');
        return true;
      }
      break;
    default:
      return false;
  }

  return false;
};

export default function Authenticator({ children, role }) {

  const navigate = useNavigate();
  const { setUser } = useContext(DataContext);
  const [cargando, setCargando] = useState(true);
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    async function fetchUserInfo() {
      try {
        const response = await Auth.currentAuthenticatedUser();
        setUserInfo(response);
        setUser(response);
      } catch (error) {
        navigate('/permiso_denegado');
      }
      setCargando(false);
    }

    fetchUserInfo();

    // eslint-disable-next-line
  }, []);

  // Para evitar renderizado doble se hace un useeffect que se encargue de que si no hay información de usuario se pueda navegar a las respectivas rutas
  useEffect(() => {
    if (!cargando) {
      if (!userInfo) {
        setUser(null);
        navigate('/iniciar_sesion', { state: { prevUrl: '/' } });
      } else if (!checkUserPermissions(userInfo, role)) {
        navigate('/permiso_denegado');
      }
    }
  }, [userInfo, role, navigate, setUser, cargando]);

  // Se verifica que no se esté cargando
  if (cargando) {
    return <Loader />;
  // Se verifica si hay un usuario autenticado y tiene permisos, de ser así se retorna el contenido
  } else if (userInfo && checkUserPermissions(userInfo, role)) {
    return children;
  } else {
    return null;
  }
}
