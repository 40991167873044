import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// Para la configuración de Router
import {BrowserRouter} from 'react-router-dom'

// Imports de amplify
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
// Imports de AWS dorectamente
import AWS from 'aws-sdk'
// Configuración de amplify
Amplify.configure(awsExports);
// Configuración para aws cognito y otros servicios
AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

