import React, {useState, useContext} from 'react'
// Context
import { DataContext } from '../../../AppContext/DataContext'
// Loader 
import Loader from '../../reusable/Loader'
// Icons
import {BsFillArrowUpLeftCircleFill, BsFillArrowUpRightCircleFill} from 'react-icons/bs'
import WebComponent from '../../reusable/WebComponent'
import MobileComponent from '../../reusable/MobileComponent'
import FullScreenDiv from '../../reusable/FullScreenDiv'

// COmponents 
import SignUpUser from './SignUpUser'
import SignupAgent from './SignupAgent'

export default function Signup() {

    // Propiedades de context
    const {loading} = useContext(DataContext)

    // Variables 
    const [roles, setRoles] = useState('agent')
    
    const [userInfo, setUserInfo] = useState({
			email:'',
			password:'',
			nombre:'',
			apellido:'',
			confirmedPassword:'',
			roles:roles,
			foto:'',
			numero:'',
			empresa:'',
			ciudad:'Santa Cruz',
    })     
    

  return (
    <>
        {/* Versión WEB */}    
        <WebComponent>
					{loading ?
						<Loader/>
						:
            <FullScreenDiv>
              <div className='w-full flex flex-row px-[5%]'>
                {/* Explicación */}
                {
                  roles === 'agent' ?
                    <div className='w-1/2 flex flex-col my-auto py-[50px] pr-[50px] pl-[30px] backdrop-blur-md rounded-xl'>
                      <p className='text-transparent bg-clip-text bg-gradient-to-r from-c2 to-c3 font-borel text-[30px] py-3 font-bold'>Cuenta de Agente Tourify:</p>
                      <p className='mt-3 font-mon text-c2 text-[20px] text-justify'>
                        Para aquellos que desean contratar nuestro servicio!
                      </p>
                      <p className='mt-5 font-mon text-c2 text-[15px] text-justify'>
                        * La cuenta de agente es específicamente para aquellas personas que quieren
                        contratar Tours virtuales, tendrán acceso a realizar solicitudes, ver sus 
                        propios tours, compartir las URLs de los Tours y muchas cosas más.
                      </p>
                    </div>
                    :
                    <div className='w-1/2 flex flex-col my-auto py-[50px] pr-[50px] pl-[30px] backdrop-blur-md rounded-xl'>
                      <p className='text-transparent bg-clip-text bg-gradient-to-r from-c2 to-c3 font-borel text-[30px] py-3 font-bold'>Cuenta de Usuario:</p>
                      <p className='mt-3 font-mon text-c2 text-[20px] text-justify'>
                        Para aquellos que solamente desean ver Tours e interactuar con la página!
                      </p>
                      <p className='mt-5 font-mon text-c2 text-[15px] text-justify'>
                        * La cuenta de usuario es para las personas que solamente quieran entrar a la 
                        página para ver Tours virtuales, así como también añadir favoritos, buscar y filtrar
                        por ciudad. Una cuenta de usuario SIEMPRE puede cambiarse a cuenta de agente Tourify en caso 
                        de querer solicitar nuestro servicio.
                      </p>
                    </div>
                }
                {/* Formulario Registro */}
                <div className='w-1/2 flex flex-col items-center justify-center'>
                  <div className='flex flex-row items-center justify-between text-c2 font-mon mt-2'>
                    <p className={`text-transparent bg-clip-text ${roles === 'agent' ? 'bg-gradient-to-r from-c2 to-c3':'bg-slate-300'} font-semibold text-[25px]`}>Agente</p>
                    <div className='w-[50px]'></div>
                    <p className={`text-transparent bg-clip-text ${roles === 'user' ? 'bg-gradient-to-r from-c2 to-c3':'bg-slate-300'} font-semibold text-[25px]`}>Usuario</p>
                  </div>
                  {/* Botón */}
                  <div 
                    className='relative flex flex-col items-center justify-center h-[30px] mb-3 cursor-pointer'
                    onClick={() => {
                      setRoles(roles === 'agent' ? 'user' : 'agent')
                      setUserInfo(roles === 'agent' ? {...userInfo, roles:'user'} : {...userInfo, roles:'agent'})
                    }}	
                  >
                    <div className='w-[60px] h-[18px] rounded-full bg-c2'></div>

                    <div   
                      className={`absolute top-0 transition-transform duration-500 ease-in-out ${roles === 'agent' ? 'left-0 translate-x-0' : 'left-0 translate-x-[30px]'}`}
                    >
                      {roles === 'agent' ? 
                        <BsFillArrowUpLeftCircleFill className='w-[30px] h-[30px] fill-c3 bg-white rounded-full'/> 
                        : 
                        <BsFillArrowUpRightCircleFill className='w-[30px] h-[30px] fill-c3 bg-white rounded-full'/>
                      }
                    </div>
                  </div>
                  <div>
                    {roles === 'user' ?
                      <SignUpUser userInfo={userInfo} setUserInfo={setUserInfo}/>
                      :
                      <SignupAgent userInfo={userInfo} setUserInfo={setUserInfo}/>
                    }
                  </div>
                </div>
              </div>
            </FullScreenDiv>
            }
        </WebComponent>
        {/* Versión MÓVIL */}
        <MobileComponent>
        {loading ?
          <Loader/>
          :
          <FullScreenDiv>
            <div className='w-full h-full flex flex-col px-[7%] backdrop-blur-sm'>
              {/* Explicación */}
              {
                roles === 'agent' ?
                  <div className='w-full flex flex-col my-auto backdrop-blur-md rounded-xl'>
                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-c2 to-c3 font-borel text-[30px] pt-3 font-bold mt-2'>Agente Tourify:</p>
                    <p className='font-mon text-c2 text-[20px] text-justify font-semibold'>
                      Para aquellos que desean contratar nuestro servicio!
                    </p>
                    <p className='mt-5 font-mon text-c2 text-[15px] text-justify'>
                      * La cuenta de agente es específicamente para aquellas personas que quieren
                      contratar Tours virtuales, tendrán acceso a realizar solicitudes, ver sus 
                      propios tours, compartir las URLs de los Tours y muchas cosas más.
                    </p>
                  </div>
                  :
                  <div className='w-full flex flex-col my-auto backdrop-blur-md rounded-xl'>
                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-c2 to-c3 font-borel text-[30px] pt-3 font-bold mt-2'>Usuario:</p>
                    <p className='font-mon text-c2 text-[20px] text-justify font-semibold'>
                      Para aquellos que solamente desean ver Tours e interactuar con la página!
                    </p>
                    <p className='mt-5 font-mon text-c2 text-[15px] text-justify'>
                      * La cuenta de usuario es para las personas que solamente quieran entrar a la 
                      página para ver Tours virtuales, así como también añadir favoritos, buscar y filtrar
                      por ciudad. Una cuenta de usuario SIEMPRE puede cambiarse a cuenta de agente Tourify en caso 
                      de querer solicitar nuestro servicio.
                    </p>
                  </div>
              }
              {/* Formulario Registro */}
              <div className='w-full flex flex-col items-center justify-center'>
                <div className='flex flex-row items-center justify-between text-c2 font-mon mt-2'>
                  <p className={`text-transparent bg-clip-text ${roles === 'agent' ? 'bg-gradient-to-r from-c2 to-c3':'bg-slate-300'} font-semibold text-[25px]`}>Agente</p>
                  <div className='w-[50px]'></div>
                  <p className={`text-transparent bg-clip-text ${roles === 'user' ? 'bg-gradient-to-r from-c2 to-c3':'bg-slate-300'} font-semibold text-[25px]`}>Usuario</p>
                </div>
                {/* Botón */}
                <div 
                  className='relative flex flex-col items-center justify-center h-[30px] mb-3 cursor-pointer'
                  onClick={() => {
                    setRoles(roles === 'agent' ? 'user' : 'agent')
                    setUserInfo(roles === 'agent' ? {...userInfo, roles:'user'} : {...userInfo, roles:'agent'})
                  }}	
                >
                  <div className='w-[60px] h-[18px] rounded-full bg-c2'></div>

                  <div   
                    className={`absolute top-0 transition-transform duration-500 ease-in-out ${roles === 'agent' ? 'left-0 translate-x-0' : 'left-0 translate-x-[30px]'}`}
                  >
                    {roles === 'agent' ? 
                      <BsFillArrowUpLeftCircleFill className='w-[30px] h-[30px] fill-c3 bg-white rounded-full'/> 
                      : 
                      <BsFillArrowUpRightCircleFill className='w-[30px] h-[30px] fill-c3 bg-white rounded-full'/>
                    }
                  </div>
                </div>
                <div>
                  {roles === 'user' ?
                    <SignUpUser userInfo={userInfo} setUserInfo={setUserInfo}/>
                    :
                    <SignupAgent userInfo={userInfo} setUserInfo={setUserInfo}/>
                  }
                </div>
              </div>
            </div>
          </FullScreenDiv>
          }
        </MobileComponent>
        
    </>
  )
}
