import React, {useEffect, useState} from 'react'
import {RiFacebookCircleFill, RiWhatsappFill} from 'react-icons/ri'
import {AiFillInstagram} from 'react-icons/ai'
import WebComponent from './WebComponent'
import MobileComponent from './MobileComponent'

export default function Footer() {

  //Para saber la orientación del dispositivo
  const [orientation, setOrientation] = useState(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait');

  useEffect(() => {
    const handleResize = () => {
      setOrientation(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait');
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);


  return ( 
    <>
      {/* Versión WEB */}
      <WebComponent>
        <div className='w-full footerBgClass bg-c2 text-white'>
          <div className='flex flex-row w-full items-center justify-between px-[5%] py-[30px]'>
            <div className='flex flex-col items-start justify-center max-w-[33%]'>
              <p className='text-[25px] font-bold'>Somos TOURIFY</p>
              <p className='text-[18px] text-justify'>
                  Un startup tecnológico basado en facilitar al usuario la forma de visitar una propiedad, brindando una solución extremadamente fácil y amigable
              </p>
            </div>
            <div className='flex flex-col items-start justify-center mt-3'>
              <p className='text-[25px] font-bold'>Nosotros</p>
              <p className='hover:underline'>Sobre Tourify</p>
              <p className='hover:underline'>Registrarse como agente</p>
              <p className='hover:underline'>Información legal</p>
              <p className='hover:underline'>Preguntas frecuentes</p>
              <p className='hover:underline'>Contáctanos</p>
            </div>
            <div className='flex flex-col items-start justify-center'>
              <p className='text-[25px] mb-2'>Redes sociales</p>
              <div className='flex flex-row w-full items-center justify-between'>
                {/* Facebook */}
                <a href="https://" target='blank' className='flex flex-row items-center justify-center text-white hover:text-c2 hover:scale-110'>
                  <RiFacebookCircleFill className='w-[34px] h-[34px]'/>
                </a>
                {/* Instagram */}
                <a href="https://" target='blank' className='flex flex-row items-center justify-center text-white hover:text-c2 hover:scale-110'>
                  <AiFillInstagram className='w-[37px] h-[37px]'/>
                </a>
                {/* Whatsapp */}
                <a href="https://" target='blank' className='flex flex-row items-center justify-center text-white hover:text-c2 hover:scale-110'>
                  <RiWhatsappFill className='w-[35px] h-[35px]'/>
                </a>
              </div>
            </div>
          </div>
          <div className='w-full pb-4 px-[5%]'>
            <div className='flex flex-row items-center justify-between text-white border-t-2 border-white pt-4'>
              <div className='flex flex-row items-center justify-center font-sans font-thin text-[15px] text-left'>
                <p className='text-[15px] text-left'>Tourify SRL</p>
                <p className='font-sans font-thin text-[25px] mx-1'>©</p>
                <p className='text-[15px] text-left'>2023</p>
              </div>
              <p className='font-sans font-thin text-[15px] ml-10 text-right'>Sitio desarrollado por Andrés Poepsel Vásquez</p>
            </div>
          </div>
        </div>
      </WebComponent>

      {/* Versión Móvil */}
      <MobileComponent>
        <div className='w-full footerBgClass bg-c2 text-white'>
          <div className='flex flex-col w-full items-center justify-between  px-[5%] py-[30px]'>
            <div className='flex flex-col items-start justify-center'>
              <p className='text-[25px] font-bold'>Somos TOURIFY</p>
              <p className='text-[18px] text-justify'>
                  Un startup tecnológico basado en facilitar al usuario la forma de visitar una propiedad, brindando una solución extremadamente fácil y amigable
              </p>
            </div>
            <div className={`w-full flex  ${orientation === 'portrait' ? 'flex-col' : 'flex-row items-center justify-between'}`}>
              <div className='flex flex-col items-start justify-center mt-5'>
                <p className='text-[25px] font-bold'>Nosotros</p>
                <p className='hover:underline'>Sobre Tourify</p>
                <p className='hover:underline'>Registrarse como agente</p>
                <p className='hover:underline'>Información legal</p>
                <p className='hover:underline'>Preguntas frecuentes</p>
                <p className='hover:underline'>Contáctanos</p>
              </div>
              <div className='flex flex-col items-start justify-center'>
                <p className='text-[25px] font-bold mt-5 mb-2'>Redes sociales</p>
                <div className='flex flex-row w-full items-center justify-around'>
                  {/* Facebook */}
                  <a href="https://" target='blank' className='flex flex-row items-center justify-center text-white hover:text-c2 hover:scale-110'>
                    <RiFacebookCircleFill className='w-[34px] h-[34px]'/>
                  </a>
                  {/* Instagram */}
                  <a href="https://" target='blank' className='flex flex-row items-center justify-center text-white hover:text-c2 hover:scale-110'>
                    <AiFillInstagram className='w-[37px] h-[37px]'/>
                  </a>
                  {/* Whatsapp */}
                  <a href="https://" target='blank' className='flex flex-row items-center justify-center text-white hover:text-c2 hover:scale-110'>
                    <RiWhatsappFill className='w-[35px] h-[35px]'/>
                  </a>
                </div>
            </div>
            </div>
          </div>
          <div className='w-full pb-4 px-[5%]'>
            <div className={`flex ${orientation === 'portrait' ? 'flex-col' : 'flex-row justify-between'} items-center text-white border-t-2 border-white pt-4`}>
              <div className='flex flex-row items-center justify-center font-sans font-thin text-[15px]'>
                <p className='text-[15px] text-left'>Tourify SRL</p>
                <p className='font-sans font-thin text-[25px] mx-1'>©</p>
                <p className='text-[15px] text-left'>2023</p>
              </div>
              <p className='font-sans font-thin text-[15px] text-center'>Sitio desarrollado por Andrés Poepsel Vásquez</p>
            </div>
          </div>
        </div>
      </MobileComponent>
    </>
  )
}
