import React, {useState, useEffect} from 'react'
import { Pannellum } from "pannellum-react";
import { customHotspotTooltip, customInfoTooltip } from '../../reusable/CustomHotspot';
import Loader from '../../reusable/Loader';
import {BiFullscreen, BiExitFullscreen} from 'react-icons/bi'
import WebComponent from '../../reusable/WebComponent';
import MobileComponent from '../../reusable/MobileComponent';
// Imágenes para el deck
import imagen1 from '../../../assets/Finales/Deck/01_Modifentrada.jpg'
import imagen2 from '../../../assets/Finales/Deck/02_Modifpasillo.jpg'
import imagen3 from '../../../assets/Finales/Deck/03_ModifpasilloMedio.jpg'
import imagen4 from '../../../assets/Finales/Deck/04_ModifpasilloFinal.jpg'
import imagen5 from '../../../assets/Finales/Deck/05_ModifoficinaCachote.jpg'
import imagen6 from '../../../assets/Finales/Deck/06_ModifoficinaAndres.jpg'
import imagen7 from '../../../assets/Finales/Deck/07_ModifareaComun.jpg'

export default function Deck() {

  // Objeto estático para las imágenes
  const config = [
    {
      id:1,
      hotSpots:[
        {
          pitch:1.3535196786619221,
          yaw:26.01913751581683,
          index:1,
          nextImage:null,
          type:"info",
          text:"Hola"
        },
        {
          pitch:1.178037189376227,
          yaw:-38.35570512476054,
          index:2,
          nextImage:{image:2, pitch:1.178037189376227, yaw:-30.35570512476054},
          type:"custom",
          text:null
        }
      ],
      image:imagen1
    },
    {
      id:2,
      hotSpots:[
        {
          pitch:-20.688831725537185,
          yaw:57.51408839014247,
          index:1,
          nextImage:{image:5, pitch:-20.688831725537185 , yaw:57.51408839014247},
          type:"custom",
          text:null
        },
        {
          pitch:-1.9454246098079473,
          yaw:-21.941174230406958,
          index:2,
          nextImage:{image:3, pitch:-1.9454246098079473 , yaw:-21.941174230406958},
          type:"custom",
          text:null
        },
        {
          pitch:-2.9511516630727384,
          yaw:123.7600414567122,
          index:3,
          nextImage:{image:1, pitch:-2.9511516630727384 , yaw:123.7600414567122},
          type:"custom",
          text:null
        }
      ],
      image:imagen2
    },
    {
      id:3,
      hotSpots:[
        {
          pitch:-2.5172770746864934,
          yaw:155.60055307933123,
          index:1,
          nextImage:{image:2, pitch:-2.5172770746864934 , yaw:155.60055307933123},
          type:"custom",
          text:null
        },
        {
          pitch:-20.249570027671812,
          yaw:24.65623412467427,
          index:2,
          nextImage:{image:6, pitch:-20.249570027671812 , yaw:24.65623412467427},
          type:"custom",
          text:null
        },
        {
          pitch:-1.7480170447685857,
          yaw:-21.360705925328592,
          index:3,
          nextImage:{image:4, pitch:-1.7480170447685857 , yaw:-180.360705925328592},
          type:"custom",
          text:null
        }
      ],
      image:imagen3
    },
    {
      id:4,
      hotSpots:[
        {
          pitch:-3.889741564039541,
          yaw:-146.83876881615186,
          index:1,
          nextImage:{image:7, pitch:-3.889741564039541 , yaw:-146.83876881615186},
          type:"custom",
          text:null
        },
        {
          pitch:-4.115437290237521,
          yaw:4.485223043696015,
          index:2,
          nextImage:{image:3, pitch:-4.115437290237521 , yaw:160},
          type:"custom",
          text:null
        }
      ],
      image:imagen4
    },
    {
      id:5,
      hotSpots:[
        {
          pitch:-15.738681663170372,
          yaw:-99.60867936736504,
          index:1,
          nextImage:{image:2, pitch:-15.738681663170372 , yaw:-30.60867936736504},
          type:"custom",
          text:null
        }
      ],
      image:imagen5
    },
    {
      id:6,
      hotSpots:[
        {
          pitch:-8.239159220266897,
          yaw:-109.93579462066936,
          index:1,
          nextImage:{image:3, pitch:-8.239159220266897 , yaw:-30.93579462066936},
          type:"custom",
          text:null
        }
      ],
      image:imagen6
    },
    {
      id:7,
      hotSpots:[
        {
          pitch:-4.686376434140728,
          yaw:13.642333380535751,
          index:1,
          nextImage:{image:4, pitch:-4.686376434140728 , yaw:13.642333380535751},
          type:"custom",
          text:null
        }
      ],
      image:imagen7
    }
  ]

  // States
  const [loading, setLoading] = useState(true)
  const [currentScene, setCurrentScene] = useState(null)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [isWebFullScreen, setIsWebFullScreen] = useState(false)
  const [zoom, setZoom] = useState(100)
  const [errorMessage, setErrorMessage] = useState()

    // Panellum ref
  let ref = React.createRef()
  // For mobile devices pannellum ref
  let mobileRef = React.createRef()
  // Full screen ref for moibile devices
  let mobileFullScreenRef = React.createRef()
  // Full screen ref for web
  let webFullScreenRef = React.createRef()

  // Funciones

  // Function to change scene
  const changeScene = (hs) => {
    console.log("Cambiando escena")
    setTimeout(()=>{
      setCurrentScene({scene:config[hs.nextImage.image-1], pitch:hs.nextImage.pitch, yaw: hs.nextImage.yaw})
      setZoom(100)
    }, 1500)
  }
  // Function to animate the transition
  const lookAt = (ref, pitch, yaw, hfov, hs) => {
    ref.current.getViewer().lookAt(pitch, yaw, hfov, 1500, changeScene(hs))
  }

  // Function to toogle Web Full screen
  const toggleWebFullScreen = () => {
    const element = webFullScreenRef.current
    if (!isWebFullScreen) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    setIsWebFullScreen(!isWebFullScreen);
  }

  // Set first scene
  useEffect(() => {
    setCurrentScene({scene:config[0], pitch:0, yaw:0})
    setLoading(false)
    // eslint-disable-next-line
  }, [])
  

  return (
    <>
      {loading ?
        <>
          <Loader/>
        </>
        :
        <>
          {/* Versión Web */}
          <WebComponent>
            <div className='flex flex-col w-full items-center backdrop-blur-md px-[10%]'>
              {errorMessage && 
                <div>
                  <p>{errorMessage}</p>
                </div>
              }
              {currentScene?.scene && 
                <div style={{ height: 'calc(100vh - 100px)' }} className='w-full flex items-center justify-center'>
                  <div className='relative flex w-full items-center justify-center' ref={webFullScreenRef}>  
                    <Pannellum
                      ref={ref}
                      width="100%"
                      height={isWebFullScreen ? `100vh`: 'calc(100vh - 140px)'}
                      image={currentScene.scene?.image}
                      pitch={0}
                      yaw={currentScene.yaw}
                      maxHfov={zoom}
                      autoLoad={true}
                      showFullscreenCtrl={false}
                      showZoomCtrl={false}
                    >
                      {currentScene.scene.hotSpots?.map((hs, i) => {

                        return hs.type === "custom" ? 
                        (
                          <Pannellum.Hotspot
                            key={i}
                            type="custom"
                            pitch={hs.pitch}
                            yaw={hs.yaw}
                            tooltip={customHotspotTooltip}
                            handleClick={() => {
                              lookAt(ref, hs.pitch, hs.yaw, 40, hs)
                            }}
                          /> 
                        )     
                          :
                        (
                          <Pannellum.Hotspot
                            key={i}
                            type="custom"
                            pitch={hs.pitch}
                            yaw={hs.yaw}
                            tooltip={customInfoTooltip}
                            tooltipArg={{text:hs.text}}
                            handleClick={()=>{}}
                          />  
                        )  
                      })}
                    </Pannellum>
                    <button
                      onClick={toggleWebFullScreen}
                      className='absolute top-0 right-0 bg-white text-black mr-3 mt-2 py-1 px-1 rounded-lg'
                    >
                      {isWebFullScreen ?
                        <>
                          <BiExitFullscreen className='w-[22px] h-[22px]'/>
                        </>
                        :
                        <>
                          <BiFullscreen className='w-[22px] h-[22px]'/>
                        </>
                      }
                    </button>
                  </div>
                </div>
              }
              
            </div>
          </WebComponent>
          {/* Versión Móvil */}
          <MobileComponent>
            <div className={`w-full flex flex-col items-center justify-center backdrop-blur-md ${isFullScreen ? 'fixed full-grow top-0 left-0 z-40':'px-[2%]'}`}>

              <div className='w-full' ref={mobileFullScreenRef}>
                {/* {errorMessage && 
                  <div>
                    <p>{errorMessage}</p>
                  </div>
                } */}
                {currentScene && 
                  <div style={{ minHeight: 'calc(100vh - 70px)' }} className='relative w-full flex items-center justify-center'>
                    <div className={`relative w-full ${!isFullScreen && ''}`}>
                      <Pannellum
                        //key={isFullScreen ? 'fullscreen':'windowed'}
                        ref={mobileRef}
                        width="100%"
                        height={isFullScreen ? `${window.innerHeight}px`: "calc(100vh - 240px)"}
                        image={currentScene.scene?.image}
                        pitch={0}
                        yaw={currentScene.yaw}
                        maxHfov={isFullScreen ? zoom-30 : zoom}
                        autoLoad={true}
                        showFullscreenCtrl={false}
                        showZoomCtrl={false}
                      >
                        {currentScene.scene.hotSpots?.map((hs, i) => {

                          return hs.type === "custom" ? 
                          (
                            <Pannellum.Hotspot
                              key={i}
                              type="custom"
                              pitch={hs.pitch}
                              yaw={hs.yaw}
                              tooltip={customHotspotTooltip}
                              handleClick={() => {
                                lookAt(mobileRef, hs.pitch, hs.yaw, 0, hs)
                              }}
                            /> 
                          )     
                            :
                          (
                            <Pannellum.Hotspot
                              key={i}
                              type="custom"
                              pitch={hs.pitch}
                              yaw={hs.yaw}
                              tooltip={customInfoTooltip}
                              tooltipArg={{text:hs.text}}
                            />  
                          )  
                        })}
                      </Pannellum>
                      <button
                        onClick={()=>setIsFullScreen(!isFullScreen)}
                        className='absolute top-0 right-0 bg-white text-black mr-3 mt-2 py-1 px-1 rounded-lg'
                      >
                        {isFullScreen ?
                          <>
                            <BiExitFullscreen className='w-[22px] h-[22px]'/>
                          </>
                          :
                          <>
                            <BiFullscreen className='w-[22px] h-[22px]'/>
                          </>
                        }
                      </button>
                      
                    </div>
                  </div>
                }
              </div>
            </div>
          </MobileComponent>
        </>
      }
    </>
  )
}
