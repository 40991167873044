/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://e2blc76usfcrnobvomlavg6jsi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-jq2h6o6w6bhf5k7weuxhxq6s2m",
    "aws_cognito_identity_pool_id": "us-east-1:ae3548c9-7dd5-4212-a937-61d2ad85fb34",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_XQTq5CMot",
    "aws_user_pools_web_client_id": "329fpkh0f6kcrd9053rvecl8qc",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "tourify130046-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
