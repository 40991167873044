import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import WebComponent from "../../reusable/WebComponent";
import MobileComponent from "../../reusable/MobileComponent";
import logo from '../../../assets/Finales/logo.png'
import nop from '../../../assets/nopermission.png'

const NoPermission = () => {

  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);

    setTimeout(() => {
      navigate('/');
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [navigate]);

  return (
    <>
      <WebComponent>
        <div style={{ height: 'calc(100vh - 100px)' }} className='hidden md:flex flex-col w-full bg-c1light items-center justify-center'>
          <div className="relative w-full h-[50%] flex flex-col items-center justify-center z-0">
            <div className="absolute flex items-center justify-center">
              <img src={nop} width={300} alt="nopermission" className="z-10"/>
              <img src={logo} width={200} alt="logo" className="absolute animate-custom-spin"/>
            </div>
          </div>
          <p className="text-c2 text-[50px] font-mon font-extrabold">PERMISO DENEGADO!</p>
          <p className="text-c2 text-[25px] font-mon font-bold"> Redireccionando en {countdown} segundos...</p>
        </div>
      </WebComponent>


      <MobileComponent>
        <div style={{ height: 'calc(100vh - 70px)' }} className='md:hidden flex flex-col w-full bg-c1light items-center justify-center'>
          <div className="relative w-full h-[60%] flex flex-col items-center justify-center z-0">
            <div className="absolute flex items-center justify-center">
              <img src={nop} width={300} alt="nopermission" className="z-10"/>
              <img src={logo} width={200} alt="logo" className="absolute animate-custom-spin"/>
            </div>
          </div>
          <p className="text-c2 text-[25px] font-mon font-extrabold">PERMISO DENEGADO!</p>
          <p className="text-c2 text-[15px] font-mon font-bold"> Redireccionando en:</p>
          <p className="text-c2 text-[15px] font-mon font-bold">{countdown} segundos...</p>
        </div>
      </MobileComponent>
      
      
    </>
  );
};

export default NoPermission;
