import React from 'react'
import gif from '../../assets/Finales/LoaderGif.gif'
import WebComponent from './WebComponent'
import MobileComponent from './MobileComponent'
import FullScreenDiv from './FullScreenDiv'

export default function Loader() {
  return (
    <>
      <WebComponent>
        <FullScreenDiv>
          <div className='w-full h-full flex flex-col items-center justify-center'>
            <div className='w-[320px] h-[320px] rounded-full bg-gradient-to-br from-c2 to-c3 flex items-center justify-center'>
              <div className='w-[300px] h-[300px] rounded-full bg-white flex items-center justify-center'>
                <img src={gif} alt="loader-gif" width={200}/>
              </div>
            </div>
          </div>
        </FullScreenDiv>
      </WebComponent>
      <MobileComponent>
        <FullScreenDiv>
          <div className='w-full h-full flex flex-col items-center justify-center'>
            <div className='w-[160px] h-[160px] rounded-full bg-gradient-to-br from-c2 to-c3 flex items-center justify-center'>
              <div className='w-[150px] h-[150px] rounded-full bg-white flex items-center justify-center'>
                <img src={gif} alt="loader-gif" width={100}/>
              </div>
            </div>
          </div>
        </FullScreenDiv>
      </MobileComponent>
    </>
  )
}