import React, {useState, useContext} from 'react'
// Context
import { DataContext } from '../../../AppContext/DataContext'
// Loader 
import Loader from '../../reusable/Loader'
// Iconos
import {FaSignInAlt} from 'react-icons/fa'
// Navegación
import { useNavigate, useLocation } from 'react-router-dom'
import MobileComponent from '../../reusable/MobileComponent'
import WebComponent from '../../reusable/WebComponent'
import FullScreenDiv from '../../reusable/FullScreenDiv'

export default function Login() {

  const navigate = useNavigate()

  const location = useLocation()

  // Propiedades de context
  const {loading, signIn} = useContext(DataContext)

  // States
  const [userInfo, setUserInfo] = useState({
    email:'',
    password:'',
  })

  // Funciones

  // Función para hacer el sign in del usuario
  const handleSignIn = (e) => {
    e.preventDefault()
    console.log(location)
    signIn(userInfo)
  }

  return (
    <>
      {/* Versión WEB */}    
      <WebComponent>
        {loading ?
          <Loader/>
          :
          <FullScreenDiv>
            <div className='relative flex flex-col bg-white text-c2 items-center justify-center shadow-md shadow-c3 py-[30px] px-[60px] min-w-[450px] min-h-[350px] rounded-tl-[36px] rounded-br-[36px] rounded-tr-[18px] rounded-bl-[18px] border-2 border-c2'>

              <p className='font-bold text-[25px]'>Iniciar sesión</p>
                  
              <form className='flex flex-col w-full items-center' onSubmit={handleSignIn}>
                <div className='flex flex-col w-full items-start'>
                  <input
                    className='w-full mt-2 font-sans py-1 px-3 rounded-md outline-none border-[1px] border-c2 font-thin' 
                    type="email" 
                    placeholder='Email' 
                    onChange={(e)=> setUserInfo({...userInfo, email:e.target.value})}
                  />
                  <input
                    className='w-full mt-2 font-sans py-1 px-3 rounded-md outline-none border-[1px] border-c2 font-thin' 
                    type="password" 
                    placeholder='Contraseña' 
                    onChange={(e)=> setUserInfo({...userInfo, password:e.target.value})}
                  />
                  <p 
                    className='mt-5 underline hover:scale-105 cursor-pointer'
                    onClick={()=>navigate('/resetear_clave')}
                  >
                    Olvidé la contraseña
                  </p>
                </div>
                    
                <button className='flex flex-row items-center justify-center mt-8 bg-gradient-to-r from-c2 to-c3 text-white text-[20px] py-2 px-3 rounded-tl-[14px] rounded-br-[14px] rounded-tr-[7px] rounded-bl-[7px] hover:scale-105 hover:bg-gradient-to-r hover:from-c2light hover:to-c3light'>
                  <p className='font-bold mr-2'>Confirmar</p>
                  <FaSignInAlt/>
                </button>
              </form>  
            </div>
          </FullScreenDiv>
        }
      </WebComponent>
      {/* Versión MÓVIL */}
      <MobileComponent>
        {loading ?
          <Loader/>
          :
          <FullScreenDiv>
            <div className='flex items-center justify-center w-full px-[7%]'>
              <div className='relative flex flex-col w-full bg-white text-c2 items-center justify-center shadow-md shadow-c3 py-[30px] px-[60px] min-h-[250px] rounded-tl-[36px] rounded-br-[36px] rounded-tr-[18px] rounded-bl-[18px] border-2 border-c2'>

                <p className='font-bold text-[25px]'>Iniciar sesión</p>
                    
                <form className='flex flex-col w-full items-center' onSubmit={handleSignIn}>
                  <div className='flex flex-col w-full items-start'>
                    <input
                      className='w-full mt-2 font-sans py-1 px-3 rounded-md outline-none border-[1px] border-c2 font-thin' 
                      type="email" 
                      placeholder='Email' 
                      onChange={(e)=> setUserInfo({...userInfo, email:e.target.value})}
                    />
                    <input
                      className='w-full mt-2 font-sans py-1 px-3 rounded-md outline-none border-[1px] border-c2 font-thin' 
                      type="password" 
                      placeholder='Contraseña' 
                      onChange={(e)=> setUserInfo({...userInfo, password:e.target.value})}
                    />
                    <p 
                      className='mt-5 underline hover:scale-105 cursor-pointer'
                      onClick={()=>navigate('/resetear_clave')}
                    >
                      Olvidé la contraseña
                    </p>
                  </div>
                      
                  <button className='flex flex-row items-center justify-center mt-8 bg-gradient-to-r from-c2 to-c3 text-white text-[20px] py-2 px-3 rounded-tl-[14px] rounded-br-[14px] rounded-tr-[7px] rounded-bl-[7px] hover:scale-105 hover:bg-gradient-to-r hover:from-c2light hover:to-c3light'>
                    <p className='font-bold mr-2'>Confirmar</p>
                    <FaSignInAlt/>
                  </button>
                </form>  
              </div>
            </div>
          </FullScreenDiv>
        }
      </MobileComponent>
      
    </>
  )
}
