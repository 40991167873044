import React from 'react'
import WebComponent from '../../reusable/WebComponent'
import MobileComponent from '../../reusable/MobileComponent'
import FullScreenDiv from '../../reusable/FullScreenDiv'
import Deck from './Deck'

export default function Content() {

  return (
    <>
      {/* Versión WEB */}
      <WebComponent>
        {/* Parte Inicial */}
        <FullScreenDiv>
          <Deck/>
        </FullScreenDiv>
        {/* Parte 1 */}
        <FullScreenDiv>
          <div className='w-full full-grow flex items-center justify-center bg-gradient-to-b from-c1 to-c2'>
            <div data-aos="fade-up" data-aos-anchor-placement="top-center" className='flex flex-col items-center justify-center'>
              <p className='text-[150px]'>Contenido 1</p>
            </div>
          </div>
        </FullScreenDiv>
        {/* Parte 2 */}
        <FullScreenDiv>
          <div className='w-full full-grow flex items-center justify-center bg-gradient-to-b from-c2 to-c3'>
            <div data-aos="fade-up" data-aos-anchor-placement="top-center" className='flex flex-col items-center justify-center'>
              <p className='text-[150px]'>Contenido 2</p>
            </div>
          </div>
        </FullScreenDiv>
        {/* Parte 3 */}
        <FullScreenDiv>
          <div className='w-full full-grow flex items-center justify-center bg-gradient-to-b from-c3 to-c1'>
            <div data-aos="fade-up" data-aos-anchor-placement="top-center" className='flex flex-col items-center justify-center'>
              <p className='text-[150px]'>Contenido 3</p>
            </div>
          </div>  
        </FullScreenDiv>
        
      </WebComponent>

      {/* Versión Móvil */}
      <MobileComponent>
        <FullScreenDiv>
          <Deck/>
        </FullScreenDiv>
      </MobileComponent>
    </>
  )
}
